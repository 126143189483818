header {
  display: block;
  width: 100%;
  height: auto;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 55px;
  margin-top: 30px;
}

.header-logo {
  display: block;
  max-width: 100%;
  height: auto;
}

.wpp-box {
  display: none;
  position: absolute;
  right: 0px;
  text-decoration: none;

  @media (min-width: 768px) {
    display: flex;
    right: 50px;
  }
  @media (min-width: 1200px) {
    right: 253px;
  }

  img {
    display: block;
    max-width: 34px;
    height: 34px;
    margin-top: 2px;
    margin-right: 5px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    color: $black;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: $black;
  }
}

.wpp-float {
  display: block;
  position: fixed;
  bottom: 0;
  right: -20px;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden ;

  @media (min-width: 576px) {
    display: none;
    bottom: 30px;
    right: 0;
  }

  img {
    display: block;
    max-width: 80%;
    height: auto;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}