.container {
  max-width: 1415px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.banner {
  display: block;
  width: 100%;
  height: 765px;
  background-image: url('/images/banner-mobile.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 70px 15px 0;
  box-sizing: border-box;

  @media (min-width: 576px) {
    padding: 85px 15px 0;
    background-image: url('/images/banner.png');
    background-position: center;
  }

  @media (min-width: 992px) {
    height: 820px;
    background-position: bottom;
  }
}

.banner-text {
  display: block;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 2.1875rem /* 35/16 */;
    font-weight: 700;
    color: $black;
    line-height: 40px;

    @media (min-width: 992px) {
      font-size: 4.0625rem /* 65/16 */;
      line-height: 70px;
    }
  }

  span {
    font-size: 2.1875rem /* 35/16 */;
    font-weight: 700;
    color: $orange;
    line-height: 40px;

    @media (min-width: 992px) {
      font-size: 4.0625rem /* 65/16 */;
      line-height: 70px;
    }
  }
}

.banner-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

/////////////////////////////////////////////

.products {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-image: url('/images/products-bg.png');
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
  margin-bottom: 30px;
  overflow: clip;

  @media (min-width: 768px) {
    justify-content: space-around;
    height: 510px;
  }
}

.products-img {
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(112,112,112,0.3); 
  box-shadow: 0px 0px 15px 5px rgba(112,112,112,0.3);
  min-width: 90px;
  max-height: 90px;
  border-radius: 5%;
  margin-right: 0;
  margin-left: 0;

  @media (min-width: 768px) {
    max-width: 120px;
    max-height: 120px;
    margin-right: 5px;
    margin-left: 5px;
  }
  @media (min-width: 1200px) {
    max-width: 182px;
    max-height: 182px;
  }

  &:nth-child(1) {
    align-self: flex-end;
    margin-bottom: 20px;

    @media (min-width: 1200px) {
      margin-bottom: 0px;
    }
  }
  &:nth-child(2) {
    align-self: flex-start;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 68px;
    }
  }
  &:nth-child(3) {
    align-self: center;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &:nth-child(4) {
    align-self: flex-end;
    margin-bottom: 60px;

    @media (min-width: 768px) {
      align-self: flex-end;
      margin-bottom: 70px;
    }
  }
  &:nth-child(5) {
    align-self: flex-start;
    margin-top: 50px;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      align-self: center;
      margin-top: 0;
      margin-bottom: 110px;
    }
  }
}

///////////////////////////////////////////

.benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1140px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.benefits-title {
  font-size: 2.1875rem /* 35/16 */;
  font-weight: 700;
  line-height: 40px;
  color: $orange;
  text-align: center;
  max-width: 700px;
  margin-bottom: 45px;

  @media (min-width: 992px) {
    font-size: 3.75rem /* 60/16 */;
    line-height: 65px;
    margin-bottom: 100px;
  }

  span {
    color: $black;
  }
}

.benefits-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  >.benefits-item {
    flex: 1 1 50%;

    @media (min-width: 768px) {
      flex: 1 1 30%;
    }
  }
}

.benefits-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  height: auto;
  margin-bottom: 58px;

  @media (min-width: 480px) {
    max-width: 300px;
  }
  @media (min-width: 768px) {
    margin-bottom: 100px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  h4 {
    font-size: 1.125rem /* 18/16 */;
    font-weight: 700;
    color: $black;
    margin-bottom: 15px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    @media (min-width: 768px) {
      font-size: 1.5625rem /* 25/16 */;
    }
  }

  p {
    font-size: .875rem /* 14/16 */;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: center;
    max-width: 230px;
    margin-left: 5px;
    margin-right: 5px;

    @media (min-width: 768px) {
      font-size: 1rem /* 16/16 */;
    }
  }
}

/////////////////////////////////////////////

.mid-content {
  display: block;
  padding: 0 5px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(255,82,52,1) 50%);
}

.mid-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 1420px;
  height: 620px;
  margin: 0 auto;
  padding-left: 15px;
  background-image: url('/images/mid-bg-mobile.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border-top-right-radius: 50px;
  position: relative;

  @media (min-width: 500px) {
    background-image: url('/images/mid-bg.png');
  }
  @media (min-width: 768px) {
    justify-content: center;
    padding-left: 115px;
    height: 900px;
  }

  h3 {
    font-size: 1.875rem /* 30/16 */;
    font-weight: 700;
    color: $white;
    line-height: 40px;
    max-width: 280px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 2.1875rem /* 35/16 */;
      max-width: 500px;
    }

    @media (min-width: 992px) {
      font-size: 3.75rem /* 60/16 */;
      line-height: 65px;
      max-width: 600px;
    }
  }

  p {
    font-size: 1rem /* 16/16 */;
    font-weight: 400;
    color: $white;
    margin-bottom: 60px;
  }
}

.resources {
  display: block;
  background-color: $orange;
  padding: 0 5px;
}

.resources-container {
  display: block;
  max-width: 1420px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px;
  border-bottom-left-radius: 50px;
  background-color: $white;
  box-sizing: border-box;

  @media (min-width: 992px) {
    padding: 100px;
  }
}

.resources-title {
  font-size: 2.1875rem /* 35/16 */;
  font-weight: 700;
  line-height: 40px;
  color: $orange;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  max-width: 700px;

  @media (min-width: 992px) {
    font-size: 3.75rem /* 60/16 */;
    line-height: 65px;
    margin-bottom: 90px;
  }

  span {
    color: $black;
  }
}

.resources-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 920px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.resources-nav {
  display: block;
  margin-bottom: 40px;

  &__slider {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0;
		margin: 0;
		list-style-type: none;
    overflow: hidden;

    @media (min-width: 768px) {
      margin-right: 30px;
      flex-direction: column;
    }

    .ativo {
      border-radius: 100px;
      background-color: $orange;
      color: $white !important;
    }
	}
}

.resources-link {
  display: block;
  width: auto !important;
  height: 30px !important;
  box-sizing: border-box !important;
	padding: 8px 15px !important;
  margin-bottom: 13px !important;
	flex-grow: 1 !important;
	cursor: pointer !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: $black !important;
  text-transform: uppercase !important;

  @media (min-width: 768px) {
    width: 145px !important;
    padding: 8px 5px 8px 20px !important;
  }
}

.resources-slider {
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  pointer-events: none;

  @media (min-width: 768px) {
    max-width: 600px;
  }
	
	.swiper-slide {
		display: flex;
    flex-direction: column;
    height: auto;
	}

  &__title {
    font-size: 1.125rem /* 18/16 */;
    font-weight: 700;
    color: $black;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 1.5625rem /* 25/16 */;
    }
  }

  &__txt {
    font-size: .875rem /* 14/16 */;
    font-weight: 400;
    color: $black;
    line-height: 24px;
    max-width: 100%;
    margin-bottom: 35px;

    @media (min-width: 768px) {
      font-size: 1rem /* 16/16 */;
      line-height: 26px;
      max-width: 560px;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  
    >.resources-slider__item {
      flex: 1 1 100%;

      @media (min-width: 576px) {
        flex: 1 1 50%;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 1rem /* 16/16 */;
    font-weight: 400;
    color: $black;

    img {
      display: block;
      max-width: 16px;
      height: 11px;
      margin-right: 15px;
    }
  }

  &__inner {
    display: flex;
    
    .swiper-slide {
      display: flex;
      align-items: center;
    }

    .swiper-pagination {
      bottom: 28px !important;

      @media (min-width: 390px) {
        bottom: 25px !important;
      }
      @media (min-width: 576px) {
        bottom: 18px !important;
      }
    }
    
    .swiper-pagination-bullet-active {
      background-color: $orange !important;
    }

    .swiper-button-next {
      top: 96%;
      right: 25%;
      color: $orange;

      @media (min-width: 420px) {
        right: 35%;
      }
      @media (min-width: 576px) {
        right: 40%;
      }

      &::after {
        font-size: .75rem /* 12/16 */;
        font-weight: 900;
      }
    }
    .swiper-button-prev {
      top: 96%;
      left: 25%;
      color: $orange;

      @media (min-width: 420px) {
        left: 35%;
      }
      @media (min-width: 576px) {
        left: 40%;
      }

      &::after {
        font-size: .75rem /* 12/16 */;
        font-weight: 900;
      }
    }

    img {
      display: block;
      max-width: 300px;
      height: auto;
    }
  }
}
///////////////////////////////////////////////

.integrations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 15px 0;
  background-color: $orange;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.integrations-title {
  font-size: 2.1875rem /* 35/16 */;
  font-weight: 700;
  color: $white;
  line-height: 40px;
  text-align: center;
  max-width: 800px;
  margin-bottom: 50px;

  @media (min-width: 992px) {
    font-size: 3.75rem /* 60/16 */;
    line-height: 65px;
  }

  span {
    color: $black;
  }
}

.integrations-text {
  font-size: .875rem /* 14/16 */;
  font-weight: 400;
  color: $white;
  line-height: 24px;
  text-align: center;
  max-width: 600px;
  margin-bottom: 35px;

  @media (min-width: 576px) {
    font-size: 1rem /* 16/16 */;
    line-height: 26px;
  }
}

.integrations-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: 992px) {
    margin-top: 65px;
    margin-bottom: 150px;
  }
}

.integrations-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 760px;

  // @media (min-width: 768px) {
  //   justify-content: center;
  //   width: 760px;
  // }

  > img {
    flex: 1 1 50%;
  }

  // &--2 {
  //   justify-content: space-evenly;
  // }

  img {
    display: block;
    max-width: 120px;
    max-height: 60px;
    margin-bottom: 30px;
    margin-left: 5px;
    margin-right: 5px;

    @media (min-width: 360px) {
      max-width: 140px;
    }
    @media (min-width: 645px) {
      max-width: 190px;
      max-height: 100px;
      margin-bottom: 50px;
    }
    @media (min-width: 992px) {
      max-width: 220px;
    }
  }
}

///////////////////////////////////////////////

.security {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0 15px;

  @media (min-width: 992px) {
    margin-top: 100px;
  }
}

.security-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-bottom: 40px;

  @media (min-width: 992px) {
    flex-direction: row;
    max-width: 1220px;
    margin-bottom: 140px;
    align-items: unset;
  }
}

.security-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3px;
  margin-right: 0px;

  @media (min-width: 992px) {
    padding-top: 50px;
    margin-right: 120px;
  }

  &__title {
    font-size: 2.1875rem /* 35/16 */;
    font-weight: 700;
    color: $orange;
    line-height: 40px;
    margin-bottom: 40px;
    max-width: 100%;

    @media (min-width: 992px) {
      font-size: 3.75rem /* 60/16 */;
      line-height: 65px;
      max-width: 600px;
    }

    span {
      color: $black;
    }
  }

  &__subtitle {
    font-size: .875rem /* 14/16 */;
    font-weight: 400;
    color: $black;
    line-height: 24px;
    margin-bottom: 35px;
    max-width: 100%;

    @media (min-width: 992px) {
      font-size: 1rem /* 16/16 */;
      line-height: 26px;
      max-width: 500px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    font-size: .875rem /* 14/16 */;
    line-height: 20px;
    font-weight: 400;
    color: $black;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      font-size: 1rem /* 16/16 */;
      line-height: 26px;
    }

    img {
      display: block;
      max-width: 16px;
      height: 11px;
      margin-right: 15px;
    }
  }
}

.security-img {
  display: block;
  max-width: 100%;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

/////////////////////////////////////////////

.clients {
  display: block;
  padding: 0 15px;
}

.clients-title {
  font-size: 1rem /* 16/16 */;
  font-weight: 400;
  color: $black;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 60px;
  padding: 0 15px;
}

.clients-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 15px;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 720px;
    justify-content: space-around;
  }
  @media (min-width: 992px) {
    margin-bottom: 110px;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    max-width: 150px;

    @media (min-width: 768px) {
      max-width: 100%;
      margin: 0 10px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.clients-divider {
  display: block;
  width: 100%;
  min-height: 70px;
  object-fit: cover;
  margin-bottom: 90px;
}

///////////////////////////////////////////

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 60px;

  @media (min-width: 992px) {
    margin-bottom: 150px;
  }

  img {
    display: block;
    max-width: 100%;
    height: 200px;
    margin-bottom: 40px;
    object-fit: cover;

    @media (min-width: 992px) {
      height: auto;
      margin-bottom: 70px;
    }
  }
}

.faq-title {
  font-size: 2.1875rem /* 35/16 */;
  font-weight: 700;
  color: $orange;
  text-align: center;
  line-height: 40px;
  margin-bottom: 15px;
  max-width: 800px;

  @media (min-width: 992px) {
    font-size: 3.75rem /* 60/16 */;
    line-height: 65px;
    margin-bottom: 40px;
    max-width: 800px;
  }

  span {
    color: $black;
  }
}

.faq-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 980px;
  width: 100%;

  @media (min-width: 992px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.faq-container {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;

  @media (min-width: 992px) {
    max-width: 480px;
    width: 100%;
    margin: 0 5px;
  }
}

.faq-items {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  padding: 0px 18px;
  border: 1px solid $black;
  border-radius: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (min-width: 992px) {
    padding: 0px 35px;
    max-width: 480px;
  }
}

.faq-input {
  display: none;
  position: relative;
  z-index: 10;
  &:checked {
		~ {
      .faq-questions {
        color: $orange;
      }

			.faq-answers {
				height: auto;
				opacity: 1;
				padding: 10px 0;
			}

      .gg-arrow-down {
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
}

.faq-questions {
  display: block;
  width: 100%;
  position: relative;
  padding: 20px 0px;
  margin: 0;
  cursor: pointer;
  font-size: .875rem /* 14/16 */;
  font-weight: 400;
  color: $black;

  @media (min-width: 992px) {
    font-size: 1rem /* 16/16 */;
    padding: 25px 0px;
  }
}

.faq-answers {
  display: block;
  margin: 5px 0;
  width: 100%!important;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  font-size: .875rem /* 14/16 */;
  font-weight: 400;
  line-height: 22px;
  color: $black;

  @media (min-width: 992px) {
    font-size: 1rem /* 16/16 */;
    line-height: 26px;
  }
}

.gg-arrow-down {
	display: flex;
  position: absolute;
  top: 25px;
  right: 10px;
  z-index: -1;
  cursor: pointer;
	box-sizing: border-box;
	transform: scale(var(--ggs,1));
  @media (min-width: 380px) {
    right: 15px;
  }
  @media (min-width: 992px) {
    top: 30px;
    right: 35px;
  }
	&::after {
		content: "";
		display: flex;
		box-sizing: border-box;
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $orange;
		border-left: 2px solid $orange;
		transform: rotate(-45deg);
	}
}

//////////////////////////////////////////////

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  max-width: 1400px;
  margin: 0 auto;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: unset;
  }
}

.about-title {
  font-size: 35px;
  font-weight: 700;
  color: $orange;
  text-align: center;
  line-height: 40px;
  max-width: 100%;
  margin-bottom: 60px;
  
  @media (min-width: 992px) {
    font-size: 3.75rem /* 60/16 */;
    line-height: 65px;
    text-align: left;
    margin-right: 15px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 0;

  }

  span {
    color: $black;
  }
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 540px;

  >.about-item {
    flex: 1 1 40%;

    @media (min-width: 576px) {
      flex: 1 1 50%;
    }
  }
}

.about-item {
  display: block;
  max-width: 220px;
  margin-bottom: 40px;
  margin-right: 4px;
  margin-left: 4px;

  @media (min-width: 576px) {
    margin-right: 0;
    margin-left: 0;
  }

  h5 {
    font-size: 1.25rem /* 20/16 */;
    font-weight: 700;
    color: $black;
    margin-bottom: 10px;

    @media (min-width: 992px) {
      font-size: 1.5625rem /* 25/16 */;
    }
  }

  p {
    font-size: .875rem /* 14/16 */;
    font-weight: 400;
    color: #666666;
    line-height: 24px;

    @media (min-width: 992px) {
      font-size: 1rem /* 16/16 */;
      line-height: 26px;
    }
  }
}

//////////////////////////////////////////////

.contact {
  display: block;
  padding: 0 5px;
  margin-top: 15px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.contact-img {
  display: flex;
  justify-content: center;

  img {
    display: block;
    max-width: 100%;
    height: 200px;
    margin-bottom: -8px;

    @media (min-width: 768px) {
      margin-bottom: -12px;
      height: auto;
    }
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1420px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  background-color: $black;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 40px 15px 0;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    padding: 50px 15px 0 80px;
  }
  @media (min-width: 992px) {
    padding: 50px 80px 0;
  }
  @media (min-width: 1368px) {
    padding: 80px 110px 0;
  }
}

.contact-wrapper {
  display: block;
  position: relative;
}

.contact-logo {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.contact-title {
  font-size: 2.1875rem /* 35/16 */;
  font-weight: 700;
  color: $white;
  line-height: 40px;
  max-width: 500px;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    font-size: 3.125rem /* 50/16 */;
    line-height: 65px;
  }
  @media (min-width: 1200px) {
    font-size: 3.75rem /* 60/16 */;
  }

  span {
    color: $orange;
  }
}

.contact-subtitle {
  font-size: 1.25rem /* 20/16 */;
  font-weight: 400;
  color: $white;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    margin-bottom: 80px;
  }
}

.contact-bg {
  display: none;
  position: absolute;
  bottom: -5px;
  max-width: 80%;
  height: auto;

  @media (min-width: 768px) {
    display: block;
    bottom: -5px;
  }
  @media (min-width: 992px) {
    position: relative;
    bottom: unset;
    margin-bottom: -10px;
  }
  @media (min-width: 1368px) {
    max-width: 100%;
    margin-bottom: -15px;
  }

  &--2 {
    display: block;
    max-width: 100%;
    height: 200px;
    padding-top: 60px;
    margin-bottom: -7px;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.contact-form {
  display: block;
  max-width: 440px;
  width: 100%;
}

//////////////////////////////////////////