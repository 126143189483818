.btn {
  display: block;
  padding: 14px 0;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  color: $black;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 180px;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;

  @media (min-width: 576px) {
    width: unset;
    padding: 14px 46px;
    margin: 0 10px;
  }

  &--orange {
    padding: 14px 0;
    color: $white;
    margin-bottom: 0;
    background-color: $orange;
    @media (min-width: 576px) {
      padding: 14px 40px;
    }
  }

  &:hover {
    background-color: $black;
    color: $white;
  }
}