footer {
  display: block;
  max-width: 1415px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  display: block;
  margin-bottom: 40px;
  padding: 0 38px;
  position: relative;
  // &::after {
  //   content: "";
  //   display: flex;
  //   position: absolute;
  //   top: 20px;
  //   z-index: 1;
  //   height: 1px;
  //   width: 500px;
  //   background-color: $black;
  // }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
  }
}

.footer-text {
  display: block;
  max-width: 560px;
  margin: 0 auto;
  margin-bottom: 36px;
  color: $black;
}

.footer-copyright {
  color: $black;
}

.footer-terms {
  color: $orange;
  margin-bottom: 25px;
  text-decoration: none;
}

.footer-socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 152px;

  a {
    height: auto;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}